<template>
  <input-typer :value="value" :delay="1000" :speed="50" :jitter="300" @done="done = $event">
    <template #default="{value, done}">
      <b-form-group :description="!done ? description_ : null" :valid-feedback="feedback_"
                    :state="done ? true : null">
        <b-form-input v-model="value" :state="done ? true : null" :placeholder="placeholder_" readonly/>
      </b-form-group>
    </template>
  </input-typer>
</template>

<script>
import InputTyper from "@/components/InputTyper";

export default {
  components: {InputTyper},
  props: {
    value: {
      type: String,
      required: true
    },
    description: {
      type: String,
      default: null
    },
    feedback: {
      type: String,
      default: null
    },
    placeholder: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      done: false
    }
  },
  methods: {
    elementGetter(term) {
      if (this[term] !== null) return this[term]

      if (this.$scopedSlots[term]) return this.$scopedSlots[term]({
        done: this.done,
        value: this.value
      })[0].text

      return null
    }
  },
  computed: {
    description_() {
      return this.elementGetter('description')
    },
    feedback_() {
      return this.elementGetter('feedback')
    },
    placeholder_() {
      return this.elementGetter('placeholder')
    }
  },
  watch: {
    done() {
      this.$emit('done', this.done)
    }
  }
}
</script>