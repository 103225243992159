<template>
  <b-card class="shadow-sm rounded-0" bg-variant="light" text-variant="dark">
    <div class="text-center">
      <slot name="header"/>
    </div>

    <slot name="fields" :done="showPassword"/>

    <b-collapse :visible="showPassword_" @shown="focusOnField">
      <b-form-group :state="complete">
        <b-input-group>
          <b-form-input v-model="value_" :state="complete" placeholder="Password" :type="exposed ? 'text' : 'password'"
                        ref="password"/>

          <template #append>
            <b-button :variant="complete ? 'outline-success' : 'outline-danger'" class="bg-white"
                      :class="[complete ? 'text-success' : 'text-danger']" @click="expose">
              <fa-icon :icon="exposed ? 'eye-slash' : 'eye'"/>
            </b-button>
          </template>
        </b-input-group>

        <template v-for="challenge in passedChallenges_">
          <b-collapse visible appear>
            <challenge-hint :challenge="challenge"/>
          </b-collapse>
        </template>
      </b-form-group>
    </b-collapse>

    <b-collapse :visible="showContinue">
      <b-button variant="success" block :disabled="!complete" @click="$emit('continue')">
        <slot name="continue">
          Continue
        </slot>
      </b-button>
    </b-collapse>

  </b-card>
</template>

<script>
import InputTyper from "@/components/InputTyper";
import ChallengeHint from "@/components/ChallengeHint";

export default {
  components: {ChallengeHint, InputTyper},
  props: {
    value: {
      type: String,
      default: ''
    },
    username: {
      type: String,
      required: true
    },
    challenges: {
      type: Array,
      default: []
    }
  },
  data() {
    return {
      showPassword_: false,
      value_: '',
      debounce: null,
      passedChallenges_: [
        {...this.challenges[0], passed: false}
      ],
      complete: false,
      exposed: false,
      showContinue: false
    }
  },

  methods: {
    sas() {
      console.log('test')
    },
    showPassword(value = true) {
      if (value)
        setTimeout(() => {
          this.showPassword_ = true
        }, 500)
    },
    focusOnField() {
      this.$refs.password.focus()
    },
    expose() {
      this.exposed = !this.exposed
      this.focusOnField()
    },
    test() {
      this.complete = false
      this.passedChallenges_ = this.passedChallenges_.map((challenge) => {
        let passed = typeof challenge.text === 'function'
            ? challenge.test
            : challenge.test.test.bind(challenge.test)
        passed = passed(this.value_)

        return {...challenge, passed}
      });

      let hasPassed = this.passedChallenges_.reduce((passed, challenge) => {
        return !passed ? false : challenge.passed
      }, true);

      if (hasPassed) {
        if (this.passedChallenges_.length === this.challenges.length) {
          this.complete = true
        } else {
          this.passedChallenges_.push({
            ...this.challenges[this.passedChallenges_.length],
            passed: false
          })
          this.test()
        }
      }
    }
  },
  watch: {
    value_(val) {
      this.$emit('input', val)

      if (this.debounce !== null) {
        clearTimeout(this.debounce)
        this.debounce = null
      }

      this.debounce = setTimeout(() => {
        this.test()
      }, 200)
    },
    value(val) {
      this.value_ = val
    },
    complete(val) {
      if (val)
        this.showContinue = true
    }
  }
}
</script>