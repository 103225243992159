<template>
  <span>
    <slot :value="value_" :done="done"/>
  </span>
</template>

<script>
export default {
  props: {
    value: {
      type: String | null,
      default: null
    },
    delay: {
      type: Number,
      default: 0
    },
    speed: {
      type: Number,
      default: 75
    },
    jitter: {
      type: Number,
      default: 0
    }
  },
  mounted() {
    this.startTyping()
  },
  data() {
    return {
      value_: null,
      done: true,
      speed_: null,
      delay_: null,
    }
  },
  methods: {
    startTyping() {
      if (this.delay_ !== null) {
        clearInterval(this.delay_)
        this.delay_ = null
      }

      if (this.speed_ !== null) {
        clearInterval(this.speed_)
        this.speed_ = null
      }

      this.done = false
      this.value_ = null

      this.delay_ = setTimeout(() => {
        if (typeof this.value === 'string') {
          this.typeAndWait(this.value.split(''))
        }
      }, this.delay)
    },
    typeAndWait(characters) {
      this.speed_ = setTimeout(() => {
        if (typeof this.value_ !== 'string')
          this.value_ = ''

        this.value_ += characters.shift()

        if (characters.length > 0)
          this.typeAndWait(characters)
        else
          this.done = true
      }, this.getSpeed())
    },
    getSpeed() {
      return this.speed + Math.floor(Math.random() * (this.jitter - 1))
    }
  },
  watch: {
    value() {
      this.startTyping()
    },
    done(val) {
      this.$emit('done', val)
    }
  }
}
</script>