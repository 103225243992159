<template>
  <span class="d-block" :class="{'invalid-feedback': !challenge.passed, 'valid-feedback': challenge.passed}">
    {{ challenge.text }}
  </span>
</template>

<script>
export default {
  props: {
    challenge: {
      type: Object,
      required: true
    }
  }
}
</script>

<style scoped>

</style>